@use '@angular/material' as mat;

//TODO: uncomment when mdb-angular-ui-toolkit supports angular 14
//@import "~mdb-angular-ui-kit/assets/scss/mdb.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

// https://material.angular.io/guide/theming


//$my-primary: mat.define-palette(mat.$indigo-palette, 500);
//$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.

// Define a light theme
$light-primary: mat.define-palette(mat.$teal-palette);
$light-accent: mat.define-palette(mat.$cyan-palette);
$light-theme: mat.define-light-theme((
        color: (
                primary: $light-primary,
                accent: $light-accent,
        )
));

// Define a dark theme
$dark-primary: mat.define-palette(mat.$amber-palette);
$dark-accent: mat.define-palette(mat.$blue-grey-palette);
$dark-theme: mat.define-dark-theme((
        color: (
                primary: $dark-primary,
                accent: $dark-accent,
        )
));

//typography
$jumbo-typography: mat.define-legacy-typography-config(
        $display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
        $display-3: mat.define-typography-level(56px, 56px, 300, $letter-spacing: -0.02em),
        $display-2: mat.define-typography-level(45px, 48px, 300, $letter-spacing: -0.005em),
        $display-1: mat.define-typography-level(34px, 40px, 300),
        $headline:  mat.define-typography-level(36px, 48px, 300),   // h1?
        $title:  mat.define-typography-level(28px, 28px, 300),        //h2
        $subheading-2:  mat.define-typography-level(12px, 12px, 300),
        $subheading-1:  mat.define-typography-level(14px, 14px, 300), // input title
        $body-1:  mat.define-typography-level(14px, 16px, 300),
        $body-2:  mat.define-typography-level(14px, 16px, 300),
        $caption:  mat.define-typography-level(14px, 16px, 300),
        $button:  mat.define-typography-level(14px, 14px, 300),
        //$input:  mat.define-typography-level(24px, 32px, 200),

        //$mat-body: mat.define-typography-level(12px, 32px, 200),
        $font-family: "Raleway"
  // ...
);

// Apply the light theme by default
@include mat.core-theme($light-theme);
@include mat.button-theme($light-theme);
@include mat.all-component-themes($light-theme);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($jumbo-typography);`
@include mat.all-component-typographies($jumbo-typography);
@include mat.core();

// Apply the dark theme only when the `.my-light-theme` CSS class is applied
// to an ancestor element of the components (such as `body`).
.dark-theme {
  @include mat.core-theme($dark-theme);
  @include mat.button-theme($dark-theme);
  @include mat.all-component-themes($dark-theme);
}

body.dark-theme {
  --accent-color: #FFC107;
  --neutral-contract-background: #5bd5cc;
  --text-on-accent: #000000;
  --text-color: #e6e6e6;
  --background-color: #333333;
  --background-2-color: #444444;
  --background-3-color: #555555;

  --hightlight-background-color: rgba(213, 31, 255, 0.76);
  a {
    color: #FFC107;
  }
}


body {
  --accent-color: #009688;
  --neutral-contract-background: #4c6065;
  --text-on-accent: #ffffff;
  --text-color: #4d4d4d;
  --background-color: #e6e6e6;
  --background-2-color: #f0f0f0;
  --background-3-color: #ffffff;
  --hightlight-background-color: #ffde48;

  font-size: 12px;
  margin: 0;
  font-weight: 200;
  background-color: var(--background-color);
  color: var(--text-color);
  a {
    color: #009688;
  }
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: square;
  padding-left: 20px;
}

ol {
  list-style-type: decimal;
  padding-left: 20px;
}


html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

app-root {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p {
  color: var(--text-color);
}

.mat-form-field-wrapper {
  padding: 0px !important;
}

.mat-form-field-underline {
  bottom: 0 !important;
}

@import "~@angular/cdk/overlay-prebuilt.css";
.ks-modal-gallery-backdrop {
  background: #000 !important;;
  opacity: 0.85 !important;;
}
.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

